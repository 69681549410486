import * as React from 'react'
import { Row, Col, Popover, PopoverBody, PopoverHeader, OutsideAlerter } from 'reactstrap'
import { accentUtils, t, format, from } from '../../services/HelperService'
import { queryNoTracking, update } from '../../services/DataService'
import { AccentSpinner } from './../AccentSpinner'
import { AccentButton } from './../AccentButton'
import { AccentSectionHeader } from './../AccentSectionHeader'
import { RTLGridCtrl } from './RTLGridCtrl'
import { isDesktop } from '../AccentDisplay'
import { RTLGridContext } from './RTLGridContext'



const query_queryNoTracking = queryNoTracking('Grid_FileNotes', 0);

export const RTLBoundGridCtrlDetails = React.memo(props => {


    const [fileNotes, setFileNotes] = React.useState(null);

    const rtlGridContext = React.useContext(RTLGridContext);

    const lookupKey = rtlGridContext?.details?.lookupKey;

    React.useEffect(() => {

        
        query_queryNoTracking.getAllWithCache({ id: props.dataItem.ID, key: lookupKey })
            .then(n => {
            setFileNotes(n);
        });

    },[]);

    const notes = accentUtils.isEmpty(props.dataItem.detailsField) ? "" : props.dataItem[props.dataItem.detailsField];

    const hasData = !accentUtils.isEmpty(fileNotes) || !accentUtils.isEmpty(notes);

    const fn = accentUtils.isNull(fileNotes) ? <AccentSpinner /> : fileNotes.map(n => {
        return <div key={ n.ID} className="accentTrackingNoteFileNote" >
            <div>{format.formatDateTime(n.Date, true, true)} {n.FullName}</div>
            <div dangerouslySetInnerHTML={{ __html: n.Notes }} />
        </div >;
    });

    if (!hasData) return null;


    return <Row>
        <Col md={6}><pre>{notes}</pre></Col>
        <Col md={6}>{fn}</Col>
    </Row>;
});



export class RTLBoundGridCtrl extends React.Component {



    constructor(props) {
        super(props);


        this.state = {
            fulltextSeachText : null
        };


        this.detailsField = from(props.columns).where(c => c.details).select(c => c.fieldName).firstOrDefault();
        this.processColumn = this.processColumn.bind(this);        
    }


    



    processColumn(col) {
        
        if (!isDesktop) {
            //col.width = 120;
        }


        if (this.props.processColumn) {
            col = this.props.processColumn(col);
        }

        return col;
    }


    render() {

        var me = this;


        const grid = this.props.hide ? null :  <RTLGridCtrl
            methods={this.props.methods}
            height={this.props.height ?? 400}
            detailsContext={!accentUtils.isNull(this.props.detailsContext) ? this.props.detailsContext : this.props.noDetails ? undefined : {
                component: RTLBoundGridCtrlDetails,
                lookupKey: this.props.detailsLookupKey
            }}
            strings={this.props.strings}
            contextKey={this.props.contextKey}
            allowFilter={this.props.allowFilter}
            allowSearch={this.props.allowSearch}
            filterSchemaName={this.props.filterSchemaName}
            className={`acc-bound-grid ${this.props.className}`}
            defaultSearchText={this.props.defaultSearchText}
            showToolbar={this.props.showToolbar}
            gridID={this.props.gridID}
            showDetails={this.props.showDetails}
            processColumn={this.processColumn}
            processExport={this.props.processExport}
            columns={this.props.columns}
            query={this.props.query}
            getActions={this.props.getActions}
            detailsField={this.detailsField}
            showCheckbox={this.props.showCheckbox ?? false}
            onRowClick={this.props.onRowClick}
            onRefresh={this.props.onRefresh}
            heightOffSet={this.props.heightOffSet}
            selectFirstRow
            withinPopup={this.props.withinPopup}
            clearFilters={this.props.clearFilters}
            actionCell={this.props.actionCell}
            hideColumnFilters={this.props.hideColumnFilters}
            sortable={ this.props.sortable}
        />;

        if (this.props.showSectionHeader) {

            var buttons = accentUtils.isNull(this.props.getActions) ? null : this.props.getActions().map(a => {

                return <AccentButton key={`a.${a.title}_${me.props.gridID}`} tagName={`a.${a.title}_${me.props.gridID}`} onClick={a.onClick} >{a.icon}{t(a.title)}</AccentButton>;
            });

            return <AccentSectionHeader title={this.props.title} lazyLoad buttons={buttons} collapsed={this.props.collapsed == undefined ? true : this.props.collapsed}>
                { this.props.preGridCtrl}
                {grid}
            </AccentSectionHeader>

        } else {
            return grid;
        }
    }

}
