import * as React from 'react'
import { Sortable } from '@progress/kendo-react-sortable'


export class SortListCtrl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items : props.items
        };

        this.onDragStart = this.onDragStart.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onNavigate = this.onNavigate.bind(this);

    }

    onDragStart(event) {
        
    }

    onDragOver(event) {
        this.setState({ items: event.newState });
    }

    onDragEnd(event) {
        this.setState({ items: event.newState });

        if (this.props.onSort) {
            this.props.onSort([...event.newState]);
        }
    }

    onNavigate(event) {
        this.setState({ items: event.newState });        
    }

    render() {

        if (this.state.items.length == 0) {
            return <p>No Data</p>;
        }

        return <Sortable
            idField={this.props.idField}
            data={this.state.items}
            itemUI={this.props.rowItem}
            disabledField={this.props.disabledField}
            onDragStart={this.onDragStart}
            onDragOver={this.onDragOver}
            onDragEnd={this.onDragEnd}
            onNavigate={this.onNavigate}
        />
    }

}



export const SortListItem = (props) => {
    const { isDisabled, isActive, style, attributes, dataItem, forwardRef } = props;
    const classNames = ['row', 'rtl-drag-chk'];

    const getBaseItemStyle = (isActive) => ({
        fontSize: '16px',
        outline: 'none',
        border: '1px solid',
        cursor: 'move',
        background: isActive ? '#27aceb' : undefined,
        color: isActive ? '#fff' : undefined,
        borderColor: isActive ? '#27aceb' : '#fff',
        overflow: "hidden"
    });

    if (isDisabled) {
        classNames.push('k-state-disabled');
    }

    return (
        <div
            ref={forwardRef}
            style={{
                ...getBaseItemStyle(isActive),
                ...style
            }}
            {...attributes}
            className={classNames.join(' ')}
        >
            {props.control(dataItem)}
        </div>
    );
};