import * as React from 'react'
import { motion } from "framer-motion"
import { ButtonDropdown, DropdownToggle, DropdownMenu, Button } from 'reactstrap'
import { MenuItem} from './MenuItem'
import { AccentTooltip } from './AccentTooltip'
import { accentUtils, t } from './../services/HelperService'
import { OutsideAlerter } from './OutsiderAlerter'
import AccentIcons from '../icons/Icons'
import { AccentPopup } from '../dialogs/AccentPopup'
import { AccentSpinner } from './AccentSpinner'


const NotificationDrop = ({ isDropping }) => {
    const ref = React.useRef(null);
    const [coords, setCoords] = React.useState({ endX: 0, endY: 0 });

    React.useEffect(() => {
        if (isDropping && ref.current) {
            const notifyElement = document.querySelector('.accentNotify');
            const notifyRect = notifyElement?.getBoundingClientRect();
            const refRect = ref.current?.getBoundingClientRect();

            if (notifyRect && refRect) {
                const endX = notifyRect.left - refRect.left + 20;
                const endY = notifyRect.top - refRect.top;
                setCoords({ endX, endY });
            }     

            setTimeout(() => {
                window.accentNotificationsRefresh();
            }, 1500);

        }
    }, [isDropping]);

    return (
        isDropping && (
            <motion.div
                ref={ref}
                initial={{ x: 0, y: 0, scale: 1 }}
                animate={{
                    x: [0, coords.endX, coords.endX],
                    y: [0, coords.endY, coords.endY],
                    scale: [1, 1.5, 0]
                }}
                transition={{
                    times: [0, 0.5, 1],  
                    duration: 1.5,  
                    ease: "easeOut"
                }}
                style={{
                    position: 'absolute',
                    zIndex: 99999
                }}
            >
                <AccentIcons.Attach />
            </motion.div>
        )
    );
};

export default NotificationDrop;

export class AccentButton extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            disabled: this.props.disabled,
            lastTime: 0,
            isNotificationDropping : false
        };

        this.handleClick = this.handleClick.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);

    }

    componentWillReceiveProps(nextProps) {

        var internalDisable = true;
        var disabled = this.state.disabled;
        if (!accentUtils.isNull(nextProps.disabled)) {
            internalDisable = false;
            disabled = nextProps.disabled;
        }

        this.setState({
            disabled: disabled,
            internalDisable: internalDisable
        });
    }

    handleClick(e) {


        var timeSinceLast = e.timeStamp - this.state.lastTime;

        //console.log("Time since last: " + timeSinceLast);

        if (timeSinceLast < 1000) {
            return;
        }

        var me = this;
        if (!accentUtils.isNull(me.props.onClick)) {

            if (!me.state.disabled) {

                me.setState(
                    { disabled: true, lastTime: e.timeStamp, internalDisable: true },
                    function () {
                        if (me.props.onClick.length > 0) {
                            new Promise(p => {
                                me.props.onClick({ promise: p, target: me });
                            }).then(function (clickResult) {

                                setTimeout(function () {
                                    if (!accentUtils.isNull(me.state.internalDisable) && me.state.internalDisable) {
                                        me.setState({ disabled: false, isNotificationDropping: clickResult?.dropNotification }, () => {
                                            setTimeout(() => {
                                                me.setState({ isNotificationDropping: false });
                                            }, 1000);
                                            
                                        });
                                    }
                                }, 1000);
                            });
                        } else {
                            me.props.onClick();
                            setTimeout(function () {
                                if (!accentUtils.isNull(me.state.internalDisable) && me.state.internalDisable) {
                                    me.setState({ disabled: false });
                                }
                            }, 1000);
                        }
                    });

            }
        }
    }

    render() {


        var size = null;
        var disabled = false;
        var style = null;
        var onClick = null;
        var className = null;
        var type = 'button';
        var key = this.props.tagName;

        var outline = true;

        if (!accentUtils.isNull(this.props.submit) && this.props.submit) {
            type = 'submit';
        }

        if (!accentUtils.isNull(this.props.outline)) {
            outline = this.props.outline;
        }

        if (!accentUtils.isNull(this.props.size)) {
            size = this.props.size;
        }

        if (!accentUtils.isNull(this.state.disabled)) {
            disabled = this.state.disabled;
        }

        if (!accentUtils.isNull(this.props.bsStyle)) {
            style = this.props.bsStyle;
        } else {
            style = "primary";
        }

        if (!accentUtils.isNull(this.props.className)) {
            className = this.props.className;
        }

        var id = this.props.id;
        if (accentUtils.isNull(id)) {
            id = key;
        }


        var button = null;

        const notificationDrop = this.props.requireNotificationDrop ? <NotificationDrop isDropping={this.state.isNotificationDropping} /> : null;

        const spinner = this.state.internalDisable && disabled && <AccentSpinner style={{ display: "inline" }} type="pulsing"/>;

        if (!accentUtils.isNull(this.props.tooltip)) {
            button = <React.Fragment>
                <AccentTooltip delayShow="500" delayHide="0" text={t(this.props.tooltip)} placement="bottom" key={'tooltip_' + key} target={id} />
                <Button id={id} key={key} type={type} size={size} active={this.props.active} outline={outline ? true : undefined} className={className} disabled={disabled} style={this.props.style} color={style} onClick={this.handleClick}>{this.props.children}{spinner}{notificationDrop}</Button>
            </React.Fragment>;
        } else {
            button = <Button id={id} key={key} type={type} size={size} active={this.props.active} outline={outline ? true : undefined} className={className} disabled={disabled} style={this.props.style} color={style} onClick={this.handleClick}>{this.props.children}{spinner}{notificationDrop}</Button>;
        }


        return (
            button
        );
    }
}


export class LongClickBtn extends React.Component {

    constructor(props) {
        super(props);

        this.doClick = false;

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        e.stopPropagation();
    }

    onMouseUp(e) {

        var me = this;

        if (me.doClick) {
            me.doClick = false;
            me.props.onClick(false);
        }

    }

    onMouseDown(e) {
        e.stopPropagation();

        if (e.button !== 0) return;

        var me = this;
        this.doClick = true;

        window.setTimeout(() => {

            if (me.doClick) {
                me.doClick = false;
                me.props.onClick(true);
            }

        }, accentUtils.isEmpty(this.props.longDelay) ? 1000 : this.props.longDelay);

    }

    render() {

        return <div className="acc-link" onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp} onClick={ this.onClick} style={this.props.style} ><this.props.icon /></div>;
    }
}



export const RTLGridRowDeleteAction = (props) => {


    const [showDelete, setShowDelete] = React.useState(false);

    const deleteClicked = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (showDelete) {
            props.onDelete(accentUtils.isNull(props.dataItem) ? props.data : props.dataItem);
        }


        setShowDelete(!showDelete);
        return false;
    }, [props, showDelete, setShowDelete]);

    const txt = accentUtils.isNull(props.text) ? "DEL" : props.text;

    const icon = props.icon ? <props.icon onClick={e => deleteClicked(e)} /> : <AccentIcons.Clear onClick={e => deleteClicked(e)} noPadding={!props.withPadding} top={ props.top} />

    const del = showDelete ? <div className="" style={{ display: props.inline ? "inline-block" : undefined,  textAlign: "center", cursor: "pointer", color: "white", backgroundColor: props.backgroundColor??  "#dc3545", minWidth:"35px", minHeight:"30px" ,  width: props.width? `${props.width}px` : undefined }} onClick={e => deleteClicked(e)}>{txt}</div>
        : <div style={{ display: props.inline ? "inline-block" : undefined, textAlign: "center", width: props.width ? `${props.width}px` : undefined }}>{icon}</div>;

    return <OutsideAlerter style={{ display: props.inline ? "inline-block" : undefined}} onAlert={() => setShowDelete(false)}>{del}</OutsideAlerter>;
}


export class AccentSplitButton extends React.Component {


    constructor(props) {
        super(props);

        
        this.toggle = this.toggle.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.state = {
            dropdownOpen: false
        };

    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }



    handleOnSelect(eventKey) {
        this.props.onSelect(eventKey);
    }

    render() {
        var me = this;

        var index = 0;

        var menuItems = this.props.children.filter(c => !accentUtils.isNull(c)).map(function (m) {
            return <MenuItem key={index++} {...m.props} onClick={me.handleOnSelect} />;
        });


        var direction = undefined;

        if (!accentUtils.isNull(this.props.direction)) {
            direction = this.props.direction;
        }

        return (
            <ButtonDropdown data-tagname={this.props.tagName} data-tagtype="SplitButton" size={this.props.size} isOpen={this.state.dropdownOpen} toggle={this.toggle} direction={direction}>
                <DropdownToggle caret color={this.props.bsStyle} size={this.props.bsSize} outline={true} >
                    {this.props.title}
                </DropdownToggle>
                <DropdownMenu>
                    {menuItems}
                </DropdownMenu>
            </ButtonDropdown>
        );
    }

    
}

export const MoreButton = props => {

    const ctrl = React.useRef();
    const [show, setShow] = React.useState(false);


    const toggle = React.useCallback(e => {

        setShow(s => !s);

    }, [setShow])

    const onClick = React.useCallback(e => {

        e.onClick(e);
        setShow(false);

    }, [props, setShow]);

    const actions = props.actions.map(function (a, i) {

        if (a === "divider") return <div key={i} className="moreSep" />;

        return <div key={a} className="p-2 acc-popup-item" style={{ cursor: "pointer"  }} onClick={e => onClick(a)}>{a.text}</div>;

    });


    const clickElement = <AccentIcons.More ref={ctrl} onClick={toggle} top={props.top ?? 0} disabled={ props.disabled} />;

    return (<div>
        {clickElement}
        <AccentPopup className={props.className} popupAlign={props.popupAlign} anchorAlign={props.anchorAlign} show={show}
            anchor={ctrl}
            onAutoClose={ e=> setShow(false)}
        >
            {actions}        
        </AccentPopup>
    </div>);

}

export const CopyButton = props => {


    const onClick = React.useCallback(e => {

        const content = props.getContent();

        navigator.clipboard.writeText(content);

    }, [props]);

    return <div className="acc-copy-btn" data-tagname={props.dataTagName}> <AccentIcons.Copy top={ props.top } onClick={onClick} toolTipText="Copy to clipboard" /> </div>;
}

