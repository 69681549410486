import * as React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { AccentSpinner } from '../controls/AccentSpinner';
import { FilterButtons } from '../controls/FilterButtons';
import { JobLineItemRender } from '../controls/jobs/JobLineItemRender';
import { ProductItemTemplate } from '../controls/products/ProductItemTemplate';
import { AccentCheckUnbound } from '../controls/unbound/AccentCheckUnbound';
import { AccentComboUnbound } from '../controls/unbound/AccentComboUnbound';
import { queryNoTracking } from '../services/DataService';
import { accentUtils, def, from, t } from '../services/HelperService';
import { Helper, jobLineTypes } from '../services/JobService';
import { getUserProductListSplitMultis } from '../services/ProductService';
import { AccentWizard, AccentWizardPage } from './RTLWizard';


const query_Job_ParentJob_JobLineSelectList = queryNoTracking("Job_ParentJob_JobLineSelectList");

export class NewServiceLineWizard extends React.Component {

    constructor(props) {
        super(props);

        this.line = props.pageprops.line;
        this.defaultType = def(this.line.LineType, jobLineTypes.Alteration);
        this.hideService = props.pageprops.hideService??false;
        
        this.state = {
            isLoading: true,
            selectedLineType: this.defaultType == jobLineTypes.Alteration ? 1 : (this.defaultType == jobLineTypes.Remake) ? 2 : 3,
            lineLinked: false,
            selectedLine: null,
            selectedProductID: null,
            lineData: []
        };


        this.componentDidMount = this.componentDidMount.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onLineTypeChanged = this.onLineTypeChanged.bind(this);
        this.onLinkChanged = this.onLinkChanged.bind(this);
        this.onSelectedLineChanged = this.onSelectedLineChanged.bind(this);
        this.onProductChanged = this.onProductChanged.bind(this);

    }

    componentDidMount() {

        query_Job_ParentJob_JobLineSelectList.getAll({ jobID: this.line.JobID }).then(r => {
            this.setState({ lineData: r, isLoading: false, lineLinked : r.length > 0});
        });


    }

    onProductChanged(e) {
        this.setState({selectedProductID : e});
    }

    onFinish() {

        var me = this;


        return new Promise(function (p) {

            const sourceLine = me.state.lineLinked && !accentUtils.isNull(me.state.selectedLine) ? me.state.selectedLine.Line : null;
            const originalLineID = accentUtils.isNull(sourceLine) ? null : sourceLine.ID;
            const selectedProductID = accentUtils.isNull(sourceLine) ? me.state.selectedProductID : null;

            if (me.state.selectedLineType == "1") {
                Helper.copyJobLineAsAlter(sourceLine, me.line, selectedProductID).then(r => p({ newLine: r, originalLineID: originalLineID }));
            } else if (me.state.selectedLineType == "2") {
                Helper.copyJobLineAsRemake(sourceLine, me.line, selectedProductID).then(r => p({ newLine: r, originalLineID: originalLineID }));
            } else {
                Helper.copyJobLineAsService(sourceLine, me.line, selectedProductID).then(r => p({ newLine: r, originalLineID: originalLineID }));
            }

        });

    }

    onLineTypeChanged(e) {

        this.setState({ selectedLineType: e.value });
    }

    onLinkChanged(e) {
        this.setState({ lineLinked: e.checked });
    }

    onSelectedLineChanged(e) {
        const selectedLine = from(this.state.lineData).firstOrDefault(l => l.Line.ID == e);

        this.setState({ selectedLine: selectedLine });

    }

    render() {


        const isLinkable = this.state.lineData.length > 0 && this.state.selectedLineType != "3";

        const showProductSelector = this.state.selectedLineType == "3" || (isLinkable && !this.state.lineLinked);

        const allowNext = !isLinkable || !this.state.lineLinked || !accentUtils.isNull(this.state.selectedLine);

        const typeOptions = [
            { ID: 1, Text: t("application_strings.wizards.newServiceLine.page01Alter"), tagName: 'v1' },
            { ID: 2, Text: t("application_strings.wizards.newServiceLine.page01Remake"), tagName: 'v2' }            
        ];

        if (!this.hideService) {
            typeOptions.push({ ID: 3, Text: t("application_strings.wizards.newServiceLine.page01Service"), tagName: 'v3' });
        }



        const productList = getUserProductListSplitMultis(null, null, false, null, false, this.state.selectedLineType != "3");

        return (
            <AccentWizard wizardProps={this.props} allowNext={allowNext}>
                <AccentWizardPage title={"application_strings.wizards.newServiceLine.page01Title"} >
                    {this.state.isLoading ? <AccentSpinner /> :
                        <React.Fragment>
                            <div className="alert alert-info" role="alert">{t("application_strings.wizards.newServiceLine.page01Message")}</div>
                            <Row>
                                <Col md={12}>
                                    <FilterButtons defaultValue={this.state.selectedLineType} options={typeOptions} onChange={this.onLineTypeChanged} tagName="serviceType" />
                                </Col>
                            </Row>
                            <hr />
                            {isLinkable && <Row>
                                <Col md={12}>
                                    <AccentCheckUnbound tagName="linkChk" defaultValue={this.state.lineLinked} onChange={this.onLinkChanged} label="application_strings.wizards.newServiceLine.page01LinkExisting" />
                                </Col>
                            </Row>}
                            {isLinkable && this.state.lineLinked && <Row>
                                <Col md={12}>
                                    <AccentComboUnbound
                                        displayField="JobLineNo"
                                        valueField="ID"
                                        required
                                        itemRender={JobLineItemRender}
                                        onChange={this.onSelectedLineChanged}
                                        label="application_strings.wizards.newServiceLine.selectLine"
                                        data={this.state.lineData.filter(l => ((this.state.selectedLineType === 1 && Helper.jobLineAllowCreateAlter(l.Line)) || (this.state.selectedLineType === 2 && Helper.jobLineAllowCreateRemake(l.Line)))  && (accentUtils.isNull(l.ServiceLine) || !l.ServiceLine.IsOpen)).map(l => l.Line)} />
                                </Col>
                            </Row>}
                            {isLinkable && this.state.lineLinked && !accentUtils.isNull(this.state.selectedLine) && !accentUtils.isNull(this.state.selectedLine.ServiceLine) && this.state.selectedLine.ServiceLine.HasService && <Row>
                                <Col md={12}>
                                    <div className="alert alert-warning" role="alert">{t("application_strings.wizards.newServiceLine.page01ExistingService", this.state.selectedLine.ServiceLine)}</div>
                                </Col>
                            </Row>}

                            {showProductSelector && <Row>
                                <Col md={12}>
                                    <AccentComboUnbound
                                        required
                                        defaultValue={ this.state.selectedProductID }
                                        displayField="Product"
                                        valueField="ID"
                                        onChange={this.onProductChanged}
                                        label="application_strings.application.dialogs.productSelectionTitle"
                                        data={productList}
                                        itemRender={ProductItemTemplate} />
                                </Col>
                            </Row>}


                        </React.Fragment>
                    }
                </AccentWizardPage>
            </AccentWizard>
        );
    }

}