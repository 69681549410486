import * as React from 'react'
import classNames from 'classnames'
import { accentUtils, t } from '../services/HelperService';

export const AccentLabel = React.memo(props => {

    const className = classNames({
        "acc-label": true,
        "acc-link": !props.disabled && !accentUtils.isNull(props.onClick)
    });


    return <div>
        <div className={className} onClick={ props.disabled ? undefined : props.onClick} >{t(props.text)}</div>
        <div>{props.children}</div>
    </div>;
});