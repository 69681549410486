import * as React from 'react'
import { accentUtils, t, showDialog, from } from '../services/HelperService'
import { AccentComboUnbound, AccentMultiComboUnbound } from '../controls/unbound/AccentComboUnbound'
import { getUserProductList } from '../services/ProductService'
import { ProductItemTemplate } from '../controls/products/ProductItemTemplate'
import { AccentDialog } from './AccentDialog'
import { AccentButton } from '../controls/AccentButton'


export function selectProduct(props) {

    return new Promise(p => {
        showDialog(<ProductSelectionDlg {...props} />).then(s => {
            p(!s.canceled ? s.product : null);
        });
    });

};


export const ProductSelectionDlg = React.memo(props => {

    const allActive = props.activeOnly;
    const all = props.all;
    

    const [allData,] = React.useState(getUserProductList(null, null, all, null, allActive).filter(i => (!props.selectMultiple || !accentUtils.isEmpty(i.ID)) && !from(props.excludeIDs ? props.excludeIDs : []).any(ex => ex.toUpperCase() == i.ID.toUpperCase())));
    const [product, setProduct] = React.useState(null);


    const onChanged = React.useCallback((e) => {

        setProduct(e);

    }, [setProduct]);

   

    const onClose = React.useCallback((e, canceled) => {
        props.onClose({ canceled: canceled, product: product });        
    },[props, product]);



    const isValid = props.allowEmpty || !accentUtils.isNull(product);


    return <AccentDialog
        headerContent={t("application_strings.application.dialogs.productSelectionTitle")}
        maxWidth={600}
        onClose={props.onClose}
        actionsContent={[
            <AccentButton key="2" tagName="ptCloseBtn" disabled={!isValid} onClick={e => onClose(e, false)}>{t("application_strings.application.buttons.ok")} </AccentButton>
        ]}
    >
        <div id="productSelectionDlg" className="center">

            {props.selectMultiple && <AccentMultiComboUnbound displayField="Product" valueField="ID" onChange={onChanged} label="application_strings.application.dialogs.productSelectionTitle" data={allData} itemRender={ProductItemTemplate} />}
            {!props.selectMultiple && <AccentComboUnbound displayField="Product" valueField="ID" onChange={onChanged} label="application_strings.application.dialogs.productSelectionTitle" data={allData} itemRender={ProductItemTemplate} />}

        </div>
    </AccentDialog>;
});