import * as React from 'react'
import { Table } from 'reactstrap'
import { accentUtils, t, from, format} from './../../services/HelperService'
import { AccentSpinner } from '../AccentSpinner'
import * as DataService from '../../services/DataService'
import { EstimatedCommissionsCtrl } from '../commissions/EstimatedCommissionsCtrl';

const query_Job_CostDetails = DataService.queryNoTracking("Job_CostDetails");
const query_CommissionEstimateForJob = DataService.queryNoTracking("CommissionEstimateForJob");

export const JobCostSummaryCtrl = React.memo(props => {

    const [jobData, setjobData] = React.useState(null);
    const [commsData, setcommsData] = React.useState(null);
    const [commsDataError, setcommsDataError] = React.useState(null);

    React.useEffect(() => {

        if (accentUtils.isNull(jobData)) {
            query_Job_CostDetails.getAll({ jobID: props.jobID }).then(r => {
                setjobData(r[0]);
            });
        }

        if (accentUtils.isNull(commsData) && props.canViewCommissions) {
            query_CommissionEstimateForJob.getFirstOrDefault({ jobID: props.jobID }).then(r => {
                if (accentUtils.isEmpty(r.Error)) {
                    setcommsData(r);
                    setcommsDataError(null);
                } else {
                    setcommsData(null);
                    setcommsDataError(r.Error);
                }
            });
        } 

    }, []);

    const hasInstallCost = costItem => {
        const install = getInstallCost(costItem);
        return install !== 0;
    }

    const getInstallCost = costItem => {
        const install = costItem.CostInstall + costItem.DimCostInstall + costItem.StandardCostInstallDiscount + costItem.StandardDimCostInstallDiscount;
        return install;
    }

    const hasInstallSale = costItem => {
        const sale = getInstallSale(costItem);
        return sale !== 0;
    }

    const getInstallSale = costItem => {
        const sale = costItem.InstallPrice + costItem.DimInstallPrice + costItem.StandardRetailInstallDiscount + costItem.StandardDimRetailInstallDiscount;
        return sale;
    }

    const getMargin = (cost, sale) => {
        const margin =
            sale !== 0 && cost !== 0
                ? (sale - cost) / sale * 100
                : 0;
        return margin;
    }

    
    if (accentUtils.isNull(jobData) || (props.canViewCommissions && accentUtils.isNull(commsData) && accentUtils.isNull(commsDataError))) {
        return <AccentSpinner />;
    }

    let totalInstallCostExTax = 0;
    let totalInstallCostDiscExTax = 0;
    let totalInstallSaleExTax = 0;
    let totalInstallSaleDiscExTax = 0;
    let totalProductCostExTax = 0;
    let totalProductSaleExTax = 0;

    const installCosts = [];
    const productCosts = [];

    jobData.LineCosts.map(l => {

        const productGroupKey =
            `${l.ProductID}${!accentUtils.isNull(l.MultiProductName) ? `${l.MultiProductID}` : ''}`;
        const productName =
            `${l.ProductName}${!accentUtils.isNull(l.MultiProductName) ? ` (${l.MultiProductName})` : ''}`;

        const olos =
            !accentUtils.isNull(l.OptionDetails) ? JSON.parse(l.OptionDetails) : null;
        const priceModel =
            !accentUtils.isNull(l.PriceDetails) ? JSON.parse(l.PriceDetails) : null;

        let lineInstallCostEx = 0;

        let lineInstallSaleEx = 0;

        if (!accentUtils.isNull(priceModel)) {
            lineInstallCostEx =
                from(priceModel.CostItems).select(c => getInstallCost(c)).sum()
            lineInstallSaleEx =
                from(priceModel.CostItems).select(c => getInstallSale(c)).sum();

            if (lineInstallCostEx !== 0 || lineInstallSaleEx !== 0) {
                // Process Install Costs by Option
                from(priceModel.CostItems).where(c => hasInstallCost(c) || hasInstallSale(c)).toArray().map(c => {
                    let groupKey = c.ItemID.toUpperCase();
                    let groupDesc = c.ItemID.toUpperCase();
                    let groupCode = '';

                    if (c.Type === 1) {
                        // Product Discount
                        groupDesc = `BASE - ${productName}`
                    } else {
                        // Option/Value Discount
                        if (!accentUtils.isNull(olos)) {
                            const olo = from(olos).where(olo => olo.ProductOptionID === groupKey || olo.ProductOptionValueID === groupKey).firstOrDefault();
                            if (!accentUtils.isNull(olo)) {
                                if (olo.ProductOptionValueName !== '') {
                                    groupKey = `${olo.ProductOptionName}${olo.ProductOptionValueName}`;
                                    groupCode = olo.ProductOptionValueCode;
                                    groupDesc = `${olo.ProductOptionName} - ${olo.ProductOptionValueName}`;
                                } else {
                                    groupKey = olo.ProductOptionName;
                                    groupCode = olo.ProductOptionCode;
                                    groupDesc = olo.ProductOptionName;
                                }
                            }
                        }
                    }

                    const installCostEx = getInstallCost(c);
                    const installSaleEx = getInstallSale(c);

                    groupKey += (installCostEx / l.Qty).toString();

                    let installCostItem = installCosts.find(cost => cost.key === groupKey);
                    if (accentUtils.isNull(installCostItem)) {
                        installCostItem = { key: groupKey, code: groupCode, desc: groupDesc, qty: l.Qty, costEx: installCostEx, saleEx: installSaleEx };
                        installCosts.push(installCostItem);
                    } else {
                        installCostItem.qty += l.Qty;
                        installCostItem.costEx += installCostEx;
                        installCostItem.saleEx += installSaleEx;
                    }
                });
            }
        }

        const installCostExTax = (l.TotalInstallCostExTax - l.TotalInstallCostAdjustmentExTax);
        const installCostDiscExTax = l.TotalInstallCostDiscountExTax;
        const installSaleExTax = (l.TotalInstallSaleExTax - l.TotalInstallSaleAdjustmentExTax);
        const installSaleDiscExTax = l.TotalInstallSaleDiscountExTax;

        totalInstallCostExTax += installCostExTax;
        totalInstallCostDiscExTax += installCostDiscExTax;
        totalInstallSaleExTax += installSaleExTax;
        totalInstallSaleDiscExTax += installSaleDiscExTax;

        const productCostEx = (l.TotalCostExTax - (installCostExTax - installCostDiscExTax));
        const productSaleEx = (l.TotalSaleExTax - (installSaleExTax - installSaleDiscExTax));

        let productItem = productCosts.find(product => product.key === productGroupKey);

        if (accentUtils.isNull(productItem)) {
            productItem = { key: productGroupKey, desc: productName, qty: l.Qty, costEx: productCostEx, saleEx: productSaleEx };
            productCosts.push(productItem);
        } else {
            productItem.qty += l.Qty;
            productItem.costEx += productCostEx;
            productItem.saleEx += productSaleEx;
        }

        totalProductCostExTax += productCostEx;
        totalProductSaleExTax += productSaleEx;

        
    });

    const installCostRows =
        from(installCosts).orderBy(c => c.code).thenBy(c => c.desc).toArray().map(c => {
            return (
                <tr>
                    <td>{c.code}</td>
                    <td>{c.desc}</td>
                    <td className="acc-num-col">{c.qty}</td>
                    <td className="acc-num-col">{format.formatCurrency((c.costEx / c.qty).toFixed(2))}</td>
                    <td className="acc-num-col">{format.formatCurrency(c.costEx)}</td>
                    <td className="acc-num-col">{format.formatCurrency(c.saleEx)}</td>
                </tr>
            )
        });

    const productCostRows =
        from(productCosts).orderBy(c => c.desc).toArray().map(c => {
            return (
                <tr>
                    <td>{c.desc}</td>
                    <td className="acc-num-col">{c.qty}</td>
                    <td className="acc-num-col">{format.formatCurrency(c.costEx)}</td>
                    <td className="acc-num-col">{format.formatCurrency(c.saleEx)}</td>
                </tr>
            )
        });

    const commsCtrl =
        props.canViewCommissions && !accentUtils.isNull(commsData)
            ? <EstimatedCommissionsCtrl data={commsData} />
            : <div>{t(commsDataError)}</div>;



    return (
        <div>
            <h5>Job Summary - {jobData.BusinessUnit}</h5>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th>Sales Rep</th>
                        <th>Cust. Ref</th>
                        <th>Job No</th>
                        <th colSpan="2">Cust. Surname</th>
                        <th colSpan="2">Cust. Firstname</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{jobData.SalesRep}</td>
                        <td>{jobData.CustomerRef}</td>
                        <td>{jobData.JobNo}</td>
                        <td colSpan="2">{jobData.CustomerLastName}</td>
                        <td colSpan="2">{jobData.CustomerFirstName}</td>
                    </tr>
                </tbody>
                <thead style={{borderTop: '0' }} >
                    <tr>
                        <th colSpan="3">Cust. Site Address</th>
                        <th colSpan="2">Cust. Ph No(s)</th>
                        <th colSpan="2">Payments</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="3">{jobData.CustomerSiteAddress}</td>
                        <td colSpan="2">{jobData.CustomerPhoneNumbers.join(' / ')}</td>
                        <td colSpan="2">{format.formatCurrency(jobData.TotalPayments)} ({format.formatCurrency(jobData.TotalSaleIncTax - jobData.TotalPayments)} Owing)</td>
                    </tr>
                </tbody>
                <thead style={{ borderTop: '0' }} >
                    <tr>
                        <th colSpan="3">Cust. Email Address</th>
                        <th>Sale Inc</th>
                        <th>Sale Ex</th>
                        <th>Cost Ex</th>
                        <th>GP%</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="3">{jobData.CustomerEmail}</td>
                        <td>{format.formatCurrency(jobData.TotalSaleIncTax)}</td>
                        <td>{format.formatCurrency(jobData.TotalSaleExTax)}</td>
                        <td>{format.formatCurrency(jobData.TotalCostExTax)}</td>
                        <td>{getMargin(jobData.TotalCostExTax, jobData.TotalSaleExTax).toFixed(2)}</td>
                    </tr>
                </tbody>
            </Table>
                
            <h5>Installation Costs</h5>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Description</th>
                        <th className="acc-num-header-col">Qty</th>
                        <th className="acc-num-header-col">Unit Cost Ex</th>
                        <th className="acc-num-header-col">Total Cost Price Ex</th>
                        <th className="acc-num-header-col">Total Sale Price Ex</th>
                    </tr>
                </thead>
                <tbody>
                    {installCostRows}
                    {(totalInstallCostDiscExTax !== 0 || totalInstallSaleDiscExTax !== 0) &&
                    <tr>
                        <td colSpan="4" className="fw-bold">Ad Hoc Install Disc</td>
                        <td className="acc-num-col fw-bold">{format.formatCurrency(-1 * totalInstallCostDiscExTax)}</td>
                        <td className="acc-num-col fw-bold">{format.formatCurrency(-1 * totalInstallSaleDiscExTax)}</td>
                    </tr>
                    }
                    <tr>
                        <td colSpan="4" className="fw-bold">Total</td>
                        <td className="acc-num-col fw-bold">{format.formatCurrency(totalInstallCostExTax - totalInstallCostDiscExTax)}</td>
                        <td className="acc-num-col fw-bold">{format.formatCurrency(totalInstallSaleExTax - totalInstallSaleDiscExTax)}</td>
                    </tr>
                </tbody>
            </Table>

            <h5>Product Costs (excluding Installation Costs)</h5>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th className="acc-num-header-col">Qty</th>
                        <th className="acc-num-header-col">Total Cost Price Ex</th>
                        <th className="acc-num-header-col">Total Sale Price Ex</th>
                    </tr>
                </thead>
                <tbody>
                    {productCostRows}
                    <tr>
                        <td colSpan="2" className="fw-bold">Total</td>
                        <td className="acc-num-col fw-bold">{format.formatCurrency(totalProductCostExTax)}</td>
                        <td className="acc-num-col fw-bold">{format.formatCurrency(totalProductSaleExTax)}</td>
                    </tr>
                </tbody>
            </Table>

            {props.canViewCommissions &&
                <div>
                    <h5>{t('application_strings.application.dialogs.estimatedCommissionsTitle')}</h5>
                    {commsCtrl}
                </div>
            }
        </div>
    );

});

