import * as React from 'react'
import { savePDF } from "@progress/kendo-react-pdf";
import { AccentButton } from "../controls/AccentButton";
import { JobCostSummaryCtrl } from "../controls/jobs/JobCostSummaryCtrl";
import { t, accentUtils, format } from "../services/HelperService";
import { AccentDialog } from "./AccentDialog";
import AccentIcons from '../icons/Icons';


export const JobCostSummaryDlg = props => {

    const pdfExportComponent = React.useRef(null);

    const exportPDFWithMethod = () => {

        if (accentUtils.isNull(pdfExportComponent.current)) return;

        let element = pdfExportComponent.current;

        const fileName = `JobCostingSummary_${props.jobReference}_${format.formatDateTimeFileName(new Date().toLocaleString())}`

        savePDF(element, {
            paperSize: "A4",
            margin: 25,
            scale: 0.65,
            fileName: fileName,
        });
    };

    return <AccentDialog
        fullscreen
        headerContent={[
            <div style={{ display: "inline-block" }} ><AccentIcons.Print onClick={exportPDFWithMethod} top={props.top} noPadding={props.noPadding} /></div>,
            t("application_strings.application.dialogs.costSummaryTitle")
        ]}
        onClose={props.onClose}
        actionsContent={[
            <AccentButton key="1" tagName="ok" onClick={props.onClose}>{t("application_strings.application.buttons.ok")}</AccentButton>
        ]}
    >
        <div ref={pdfExportComponent}>
            <JobCostSummaryCtrl jobID={props.jobID} canViewCommissions={props.canViewCommissions} />
        </div>

    </AccentDialog>;

};