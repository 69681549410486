import * as React from 'react'
import authService from './AuthorizeService'
import { registerServices } from './../services/registerServices'
import { accentUtils } from '../services/HelperService';

const App = React.lazy(() => import('../../App'));

function AuthenticatedApp() {

    
    
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [isLoadingServices, setIsLoadingServices] = React.useState(true);



    async function initApp() {

        const instance = await authService.Initialize();

        console.log("initApp: 1");

        const accounts = instance.getAllAccounts();

        console.log("initApp: 2");


        const instanceIsAuthenticated = accounts && accounts.length > 0;

        instance.handleRedirectPromise().then((response) => {
            if (response !== null || instanceIsAuthenticated) {

                console.log("handleRedirectPromise: response - " + !accentUtils.isNull(response));
                console.log("handleRedirectPromise: instanceIsAuthenticated - " + instanceIsAuthenticated);


                if (window.loadTimeoutID) {
                    clearTimeout(window.loadTimeoutID);
                }

                setIsAuthenticated(true);
                loadServices();
            }
        }).catch((error) => {
            console.error(error);
        });



        if (instanceIsAuthenticated) {
            setIsAuthenticated(true);
        } else {

            try {

                await instance.loginRedirect(authService.getLoginRequest());

            } catch (e) {
                console.log(e);
            }

        }

    }

    function loadServices() {

        setIsLoadingServices(true);

        setTimeout(() => {

            registerServices().then(done => {

                setIsLoadingServices(false);

            });

        }, 10);

    }

    React.useEffect(() => {

        initApp();

    }, []);


    if (!isAuthenticated) {
        return <div className="center-screen app-loading-msg">Authenticating</div>;
    }


    if (isLoadingServices) {
        return <div className="center-screen app-loading-msg">Loading Services</div>;
    }

    return (
        <App />
    );
}




export default AuthenticatedApp;